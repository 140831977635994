<template>

                  <div class="flex gap-2">
                    <feather-icon icon="PlusIcon" class="mx-auto" />
                    <span> {{title}} </span>
                  </div>

</template>

<script>
import {
  BButton,
} from "bootstrap-vue";
export default {
  props: ['title'],
    compoments: {
        BButton
    }
}
</script>

<style>

</style>